<template>
  <div v-if="data">
    <bread-crumb></bread-crumb>
    <div class="blog__descr" v-html="data.description"></div>
    <h2 class="blog__title">{{ data.title }}</h2>
    <div class="blog__inner">
      <div class="blog__inner--news" v-if="data.actual_post">
        <div class="blog__inner--img" v-if="data.actual_post.image">
          <v-lazy-image
            :src="url + data.actual_post.image"
            rel="preload"
            width="641"
            height="320"
            alt="sdf"
          />
        </div>
        <div class="blog__inner--title">
          {{ getTranslatedName(data.actual_post.translations, $i18n.locale) }}
        </div>
        <div
          class="blog__inner--text"
          v-html="
            getTranslatedDescription(
              data.actual_post.translations,
              $i18n.locale
            )
          "
        ></div>
        <router-link
          :to="{ name: 'BlogNews', params: { name: data.actual_post.slug } }"
          class="blog__inner--link"
        >
          {{ t("more_details") }}
        </router-link>
      </div>
      <div class="blog__inner--info info-inner">
        <router-link :to="{ name: 'BlogInfo' }" class="info-inner__item">
          <div class="info-inner__item--img">
            <v-lazy-image
              :src="url + data.ii_cover"
              rel="preload"
              width="361"
              height="309"
              alt="sdf"
            />
            <span>{{ data.ii_title }}</span>
          </div>
        </router-link>
        <router-link :to="{ name: 'BlogHumor' }" class="info-inner__item">
          <div class="info-inner__item--img">
            <v-lazy-image
              :src="url + data.humor_cover"
              rel="preload"
              width="361"
              height="309"
              alt="sdf"
            />
            <span>{{ data.humor_title }}</span>
          </div>
        </router-link>
      </div>
    </div>
    <div class="blog__news news">
      <article class="news__item" v-for="post in news" :key="post.id">
        <router-link
          tag="a"
          :to="{ name: 'BlogNews', params: { name: post.slug } }"
        >
          <div class="news__date">{{ formatDate(post.updated_at) }}</div>
          <div class="news__descr" v-html="post.description"></div>
        </router-link>
      </article>
      <div class="news__wrap">
        <button class="news__all" @click="getMoreNews()">
          {{ t("load_more") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

const BreadCrumb = defineAsyncComponent(() =>
  import("@/components/BreadCrumb")
);
import VLazyImage from "v-lazy-image";
import axios from "axios";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "BlogIndex",
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
  components: {
    BreadCrumb,
    VLazyImage,
  },
  props: {
    url: {
      type: String,
      default: process.env.VUE_APP_API,
    },
  },
  data() {
    return {
      locale: this.$i18n.locale,
      data: [],
      news: [],
      page: 1,
    };
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);
      const options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: false,
      };
      return new Intl.DateTimeFormat("uk-UA", options).format(date);
    },
    async getMoreNews() {
      try {
        this.page += 1;
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            page: this.page,
          },
        };
        const response = await axios.get(
          process.env.VUE_APP_API + "/api/" + this.locale + "/posts",
          config
        );
        this.news = [...this.news, ...response.data.data];
      } catch (e) {
        alert("Ошибка");
      }
    },
    async getNews() {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const response = await axios.get(
          process.env.VUE_APP_API + "/api/" + this.locale + "/posts",
          config
        );
        this.news = response.data.data;
      } catch (e) {
        alert("Ошибка запроса");
      } finally {
        //что-то делаем после завершения
      }
    },
    getTranslatedName(transArray, locale) {
      let name = "";
      transArray.forEach((element) => {
        if (element.locale === locale) {
          name = element.name;
        }
      });
      return name;
    },
    getTranslatedDescription(transArray, locale) {
      let description = "";
      transArray.forEach((element) => {
        if (element.locale === locale) {
          description = element.description;
        }
      });
      return description;
    },
    async getBlogPage() {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const response = await axios.get(
          process.env.VUE_APP_API + "/api/" + this.locale + "/blog-page",
          config
        );
        this.data = response.data.data;
      } catch (e) {
        alert("Ошибка запросса");
      } finally {
        //что-то делаем после завершения
      }
    },
  },
  created() {
    this.getBlogPage();
    this.getNews();
  },
});
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/_mixins.scss";

.blog {
  &__descr {
    max-width: 800px;
    margin: 0 auto 1em;
    line-height: calc(25 / 16);
  }

  &__title {
    text-align: center;
    font-weight: 700;
    @include property("font-size", 42, 34);
    line-height: 1;
    color: #000000;
    text-transform: uppercase;
    @include property("margin-top", 40, 20);
    @include property("margin-bottom", 60, 30);
  }

  &__inner {
    display: grid;
    @include property("grid-column-gap", 150, 80, true, 1366, 1024);
    @include property("grid-row-gap", 60, 20);
    @include property("margin-bottom", 70, 20);

    @media (min-width: 768px) {
      grid-template-columns: 1.25fr 1fr;
      margin-right: 20px;
    }

    &--news {
      max-width: 500px;
      margin: 0 auto;
    }

    &--img {
      position: relative;
      padding-bottom: 70%;
      @include property("margin-bottom", 30, 15);

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &--title {
      @include property("font-size", 24, 20);
      letter-spacing: 0.48px;
      color: #f9b80e;
      @include property("margin-bottom", 25, 15);
    }

    &--text {
      @include property("font-size", 24, 20);
      letter-spacing: 0.48px;
      @include property("margin-bottom", 20, 15);
    }

    &--link {
      letter-spacing: 2.4px;
      color: #f9b80e;
      line-height: calc(30 / 16);
    }
  }
}

.info-inner {
  &__item {
    display: block;
    &:not(:last-child) {
      @include property("margin-bottom", 80, 40);
    }

    &--img {
      position: relative;
      padding-bottom: 85%;

      @media (max-width: 767.98px) {
        margin: 0 20px 0 30px;
      }

      @media (max-width: 575.98px) {
        margin: 0 20px;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      span {
        position: absolute;
        top: -20px;
        left: -20px;
        display: block;
        width: 100%;
        z-index: 4;
        padding: 5px 11px;
        background: #f9b80e;
        @include property("font-size", 32, 21);
        font-weight: 700;
        color: #000000;
        text-transform: uppercase;

        @media (min-width: 768px) {
          left: -60px;
        }

        @media (max-width: 575.98px) {
          top: -15px;
        }
      }

      &::before {
        content: "";
        position: absolute;
        top: -20px;
        left: 20px;
        width: 100%;
        height: 100%;
        border: 1px solid #f9b80e;
        z-index: 3;

        @media (max-width: 575.98px) {
          top: -15px;
          left: 15px;
        }
      }
    }
  }
}

.news {
  display: grid;
  @include property("grid-column-gap", 150, 40, true, 1366, 1024);
  @include property("grid-row-gap", 60, 20);
  @include property("margin-bottom", 100, 30);

  @media (min-width: 576px) {
    grid-template-columns: repeat(2, 1fr);
  }

  &__date {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 3px solid #f9b80e;
    letter-spacing: 0.8px;
    color: #f9b80e;
  }

  &__descr {
    line-height: calc(25 / 16);
    letter-spacing: 0.8px;
    color: #1c1c1c;
    margin: 0;
  }

  &__wrap {
    display: flex;
    justify-content: flex-end;
    @include property("margin-top", -30, -10);

    @media (min-width: 576px) {
      grid-column: 2 span;
    }
  }

  &__all {
    align-self: flex-end;
    line-height: calc(30 / 16);
    letter-spacing: 2.4px;
    color: #f9b80e;
  }
}
</style>
